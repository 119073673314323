<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Hier können iTex-Artikel erstellt, gelöscht oder editiert werden.</span
          >
        </div>
        <!--end::Info-->

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-container>
            <v-row style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Neuer Artikel
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  sort-by="CompanyName"
                  class="table1"
                  :loading="loadingvariable"
                  loading-text="Bitte warten... Artikel werden geladen"
                >
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} von {{ items.itemsLength }}
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ getFormatedPrice(item.price) }}
                  </template>
                  <template v-slot:item.unit_id="{ item }">
                    {{ item.itemsunit.length > 0 ? item.itemsunit[0].name : "" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      title="Edit user"
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
<!--                    <v-icon
                      title="Copy"
                      small
                      class="mr-2"
                      @click="copyItem(item)"
                    >
                      mdi-content-copy
                    </v-icon>-->
                    <v-icon
                      title="Delete user"
                      small
                      class="mr-2"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    Keine Artikel vorhanden
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="600px">
              <!--              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                  Create new client
                </v-btn>
              </template>-->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.internal_number"
                              label="Artikel Nr."
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                              :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Name*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-currency-field
                            v-model="editedItem.price"
                            label="Preis*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-currency-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.supplier"
                              label="Lieferant"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.design_name"
                              label="Design Name"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.material"
                              label="Material"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.color"
                              label="Farbe"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.m2_weight"
                              label="m2 Gewicht"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.packaging"
                              label="Verpackung"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.supplier_number"
                              label="Lieferantennummer"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.net_weight"
                              label="Netto Gewicht"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.commission"
                              label="Kommission"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.order_quantity"
                              label="Bestellmenge"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.labeling"
                              label="Kennzeichnung"
                              filled
                              auto-grow
                              background-color="#f7f7f7"
                              name="input-7-1"
                              dense
                              clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div v-if="formInvalid" class="fillAllFieldsMessage">
                        {{ formInvalidMessage }}
                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="secondary p-8" large color="primary darken-1" text @click="close">
                    Abbrechen
                  </v-btn>
                  <v-btn class="primary p-8" large text @click="save">
                    Senden
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-app>
      </div>
    </div>
  </div>
</template>
<style>
.v-data-footer__select {
  display: none !important;
}
.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// eslint-disable-next-line no-unused-vars
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
Vue.use(Loading);

export default {
  name: "items",
  components: { Loading },
  data: function() {
    return {
      pageTitle: "Artikelverwaltung",
      dialog: false,
      loadingvariable: false,
      headers: [
        { text: "Artikel Nr.", value: "internal_number" },
        { text: "Artikel", value: "name" },
        { text: "Lieferant", value: "supplier" },
        { text: "Preis", value: "price" },
        { text: "Optionen", value: "actions", sortable: false }
      ],
      totalItems: 0,
      options: { page: 1 },
      items: {},
      editedIndex: -1,
      editedItem: {
        id: "",
        internal_number: "",
        name: "",
        price: "",
        supplier: "",
        design_name: "",
        material: "",
        color: "",
        m2_weight: "",
        packaging: "",
        supplier_number: "",
        net_weight: "",
        commission: "",
        order_quantity: "",
        labeling: "",
        unit_id: 1
      },
      unit_item: null,
      formInvalidMessage: "*Bitte beachten Sie die Pflichtfelder",
      formInvalid: false,
      rules: {
        fullPage: false,
        required: value => !!value || "Pflichtfeld",
        formHasErrors: false,
        min: v => (v && v.length >= 8) || "Min. 8 Zeichen",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      isLoading: false
    };
  },
  created() {
    this.items = [];
    this.getDataFromApi();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Overview" }]);
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Artikel" : "Editiere Artikel";
    },
    /*price: {
      get: function() {
        if (parseFloat(this.editedItem.price) > 0) {
          //return this.editedItem.price / 1000;
        }
        //return this.editedItem.price / 1000;
      },
      set: function(newValue) {
        this.editedItem.price = newValue;
        if (parseFloat(newValue) > 0) {
          //this.editedItem.price = Math.round(newValue * 100);
          this.editedItem.price = newValue * 1000;
        } else {
          this.editedItem.price = newValue * 1000;
        }
      }
    }*/
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    initializePrice() {
      if (parseFloat(this.editedItem.price) > 0) {
        this.editedItem.price = this.editedItem.price / 1000;
      }
    },
    getDataFromApi() {
      this.loadingvariable = true;
      ApiService.query("/items?page=" + this.options.page)
        .then(({ data }) => {
          this.loadingvariable = false;
          this.items = data.items.data;
          this.totalItems = data.items.total;
          this.unit_item = data.unit_item;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.loadingvariable = false;
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    deleteItem(item) {
      Swal.fire({
        title: "Artikel löschen",
        text: "Möchten Sie diesen Artikel wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Löschen",
        cancelButtonText: "Abbrechen"
      }).then(invoice => {
        if (invoice.value) {
          this.isLoading = true;
          ApiService.delete("items/" + item.id)
            .then(() => {
              location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.isLoading = false;
            });
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      this.$nextTick(() => {
        // Rufe initializePrice auf, wenn ein Element bearbeitet wird
        this.initializePrice();
      });
    },
    copyItem(item) {
      //this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    getFormatedPrice(price) {
      if (parseFloat(price) > 0) {
        return price / 1000;
      }
      return price;
    },
    setFormatedPrice(price) {
      if (parseFloat(price) > 0) {
        price = Math.round(price * 100);
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        });
        if (parseFloat(this.editedItem.price) > 0) {
          this.editedItem.price = this.editedItem.price * 1000;
        } else {
          this.editedItem.price = this.editedItem.price * 1000;
        }
        if (this.editedIndex > -1) {
          if (this.editedItem.hasOwnProperty("itemsunit"))
            delete this.editedItem["itemsunit"];
          ApiService.put("items/update", this.editedItem)
            .then(response => {
              let item = response.data.items;
              if (item) {
                Object.assign(this.items[this.editedIndex], item);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(response => {
              this.formInvalidMessage = response.data.message;
              this.formInvalid = true;
              loader.hide();
            });
        } else {
          ApiService.post("items/add", this.editedItem)
            .then(({ data }) => {
              if (data.items) {
                this.items.splice(0,0, data.items);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(response => {
              this.formInvalidMessage = response.data;
              this.formInvalid = true;
              loader.hide();
            });
        }
      } else this.formInvalid = true;
    },
    resetForm() {
      this.formInvalid = false;
      for (var key in this.editedItem) {
        this.editedItem[key] = "";
      }
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
